<template>
  <div>
    <transition name="slidedown">
      <div v-if="showSearch" class="absolute top-0 left-0 w-full bg-f-bg bo:bg-f-white py-8 z-70">
        <x-mark-icon
          class="w-8 absolute top-2 right-2 text-f-white bo:text-f-tertiary cursor-pointer"
          @click="closeSearchBar" />
        <div class="w-full flex items-center justify-center mb-8">
          <logo />
        </div>
        <div class="w-9/12 bg-f-tertiary bo:bg-f-white p-6 rounded-xl border-2 border-f-bg/[.07] hj:border-0 mx-auto">
          <SearchBar
            :activities="searchForm.activities"
            :venues="searchForm.venues"
            :reservation-id="reservationId"
            @search-finished="searchFinished" />
        </div>
      </div>
    </transition>

    <div class="hj:text-f-white">
      <div class="text-center">
        <div v-if="headingText || subHeadingText" class="-mb-4 mt-12">
          <h1 class="bo:font-graphik-bold hj:font-druk-heavy-italic hj:text-f-white text-3xl text-center mb-2">
            {{ headingText }}
          </h1>
          <p class="text-f-bg bo:text-f-tertiary hj:text-f-white text-center">{{ subHeadingText }}</p>
        </div>
        <SearchCriteria :activities="searchForm.activities" :venues="searchForm.venues" @click="showSearchBar" />
        <!--        <div v-if="selectedBookingSlot" class="flex flex-col items-center justify-center mt-10">-->
        <!--          <p class="text-f-bg bo:text-f-tertiary hj:text-f-white font-bold mb-2">Your selection</p>-->
        <!--          <SelectedSlot :activities="searchForm.activities" :venues="searchForm.venues" />-->
        <!--        </div>-->
      </div>

      <div v-if="loading" class="w-full md:w-7/12 px-5 md:px-0 mt-20 mx-auto relative">
        <div class="absolute absolute-center-x mt-36">
          <loading-spinner size="medium" />
        </div>
      </div>

      <!--  ##TIME MODE##  -->
      <div v-if="activeMode === displayModes.time" class="w-full md:w-7/12 px-5 md:px-0 mt-20 mx-auto">
        <p class="bo:font-graphik-semibold hj:font-druk-heavy-italictext-f-white text-lg text-center mb-5">
          Please select a time below to continue your booking
        </p>
        <h1
          class="bo:font-graphik-bold hj:font-druk-heavy-italic text-f-bg text-3xl hj:text-4xl text-center mb-6 hj:text-f-white">
          Available sessions
        </h1>
        <div class="w-full grid grid-cols-16 mb-6 h-[82px] b-calendar">
          <div class="w-full col-span-2 md:col-span-1 text-center py-2">
            <p class="bo:font-graphik-medium hj:font-druk-bold">
              {{ getDateTime(searchResults.date).toFormat("ccc") }}
            </p>
            <p class="bo:font-graphik-semibold hj:font-druk-heavy-italic text-2xl">
              {{ getDateTime(searchResults.date).toFormat("dd") }}
            </p>
            <p class="bo:font-graphik-medium hj:font-druk-bold">
              {{ getDateTime(searchResults.date).toFormat("LLL") }}
            </p>
          </div>
          <div class="w-full col-span-10 md:col-span-13 col-start-3 col-start-5 md:col-start-3">
            <vue-horizontal-es responsive>
              <div v-for="(data, time) in availabilities[searchResults.date]">
                <div
                  v-if="data.available === true"
                  class="w-[150px] text-center py-7 px-5 mx-2 rounded-lg border hover:border-f-bg hover:hj:border-white cursor-pointer drop-shadow-md"
                  :class="{
                    'bg-f-contrast border-f-bg hj:border-white b-drop-shadow-light hj:b-drop-shadow':
                      time === selectedSlot.time && searchResults.date === selectedSlot.date,
                    'border-transparent hj:border-f-tertiary bg-f-tertiary/[.07] hj:bg-f-tertiary':
                      time !== selectedSlot.time || searchResults.date !== selectedSlot.date,
                  }"
                  @click="selectSlot(searchResults.date, time)">
                  {{ time }}<br /><span
                    :class="{
                      'text-f-tertiary': time === selectedSlot.time && searchResults.date === selectedSlot.date,
                    }"
                    class="text-f-contrast font-bold"
                    >from £{{ getFromPriceForTime(time) }}</span
                  >
                </div>
                <div
                  v-if="data.available === false && showFullyBookedTimes"
                  class="w-[150px] text-center text-sm py-6 px-5 mx-2 rounded-lg text-f-bg bo:text-f-tertiary/[.35] hj:text-f-white/50 bg-f-tertiary/[.07] hj:bg-f-tertiary/40 drop-shadow-md">
                  {{ time }}<br />FULLY BOOKED
                </div>
              </div>
              <template v-slot:btn-prev>
                <div class="p-carousel-prev">
                  <div class="p-carousel-prev-icon"></div>
                </div>
              </template>
              <template v-slot:btn-next>
                <div class="p-carousel-next ml-14">
                  <div class="p-carousel-prev-icon"></div>
                </div>
              </template>
            </vue-horizontal-es>
          </div>
        </div>
      </div>
      <!--  ##ACTIVITY MODE##  -->
      <div v-else-if="activeMode === displayModes.activity" class="w-full md:w-7/12 px-5 md:px-0 mt-20 mx-auto">
        <h1
          class="bo:font-graphik-bold hj:font-druk-heavy-italic text-f-bg text-3xl hj:text-4xl text-center mb-6 hj:text-f-white">
          We're fully booked on
          {{ getDateTime(searchParams.when.value, "yyyy-MM-dd HH:mm").toFormat("EEE, dd MMM") }} for
          {{ searchParams.activity.name }}.<br />Here are some available activities at
          {{ getDateTime(searchParams.when.value, "yyyy-MM-dd HH:mm").toFormat("HH:mm") }}
        </h1>
        <div class="mt-20 mb-6 h-[82px] b-calendar">
          <div class="w-9/12 md:w-10/12 lg:w-6/12 mx-auto">
            <vue-horizontal-es responsive>
              <div
                v-for="(activityName, activityId) in availabilities[searchResults.date][searchResults.time].activities">
                <div
                  class="max-w-[150px] min-w-[160px] text-center py-7 px-5 mx-2 rounded-lg border hover:border-f-bg hover:hj:border-white cursor-pointer drop-shadow-md"
                  :class="{
                    'bg-f-contrast border-f-bg hj:border-white b-drop-shadow-light hj:b-drop-shadow':
                      parseInt(activityId) === selectedActivity.id,
                    'border-transparent hj:border-f-tertiary bg-f-tertiary/[.07] hj:bg-f-tertiary':
                      parseInt(activityId) !== selectedActivity.id,
                  }"
                  @click="selectActivity(getActivity(activityId))">
                  {{ activityName }}
                </div>
              </div>
              <template v-slot:btn-prev>
                <div class="p-carousel-prev">
                  <div class="p-carousel-prev-icon"></div>
                </div>
              </template>
              <template v-slot:btn-next>
                <div class="p-carousel-next">
                  <div class="p-carousel-prev-icon"></div>
                </div>
              </template>
            </vue-horizontal-es>
          </div>
        </div>
      </div>
      <!--  ##VENUE MODE##  -->
      <div v-else-if="activeMode === displayModes.venue" class="w-full md:w-7/12 px-5 md:px-0 mt-20 mx-auto">
        <h1
          class="bo:font-graphik-bold hj:font-druk-heavy-italic text-f-bg text-3xl hj:text-4xl text-center mb-6 hj:text-f-white">
          We're fully booked at {{ searchParams.venue.name }} on
          {{ getDateTime(searchParams.when.value, "yyyy-MM-dd HH:mm").toFormat("EEE, dd MMM") }}.<br />Why not try
          another venue?
        </h1>
        <div class="mt-20 mb-6 h-[82px] b-calendar">
          <div class="w-9/12 md:w-10/12 lg:w-6/12 mx-auto">
            <vue-horizontal-es responsive>
              <div v-for="(venueName, venueId) in availabilities[searchResults.date][searchResults.time].venues">
                <div
                  class="max-w-[150px] min-w-[160px] text-center py-7 px-5 mx-2 rounded-lg border hover:border-f-bg hover:hj:border-white cursor-pointer drop-shadow-md"
                  :class="{
                    'bg-f-contrast border-f-bg hj:border-white b-drop-shadow-light hj:b-drop-shadow':
                      parseInt(venueId) === selectedVenue.id,
                    'border-transparent hj:border-f-tertiary bg-f-tertiary/[.07] hj:bg-f-tertiary':
                      parseInt(venueId) !== selectedVenue.id,
                  }"
                  @click="selectVenue(getVenue(venueId))">
                  {{ venueName }}<br />{{ getDateTime(searchParams.when.value, "yyyy-MM-dd HH:mm").toFormat("HH:mm") }}
                </div>
              </div>
              <template v-slot:btn-prev>
                <div class="p-carousel-prev">
                  <div class="p-carousel-prev-icon"></div>
                </div>
              </template>
              <template v-slot:btn-next>
                <div class="p-carousel-next">
                  <div class="p-carousel-prev-icon"></div>
                </div>
              </template>
            </vue-horizontal-es>
          </div>
        </div>
      </div>
      <div
        class="fixed left-0 bottom-0 w-full bg-f-white hj:bg-f-tertiary py-4 text-center border-t-2 border-t-f-bg/[.07] hj:border-t-white/10 px-4">
        <button
          type="button"
          class="bo:font-graphik-medium hj:font-druk-bold hj:text-2xl font-bold hj:font-normal rounded-full hj:rounded-xl py-3 w-full md:w-3/12 lg:w-2/12"
          :class="{
            'bg-f-contrast/30 text-f-tertiary/30 cursor-not-allowed': !canContinue,
            'bg-f-contrast text-f-tertiary hj:text-white hover:text-f-white hover:bg-f-tertiary hover:hj:bg-f-secondary hover:hj:bg-f-primary':
              canContinue,
          }"
          :disabled="!canContinue"
          @click="completeSelection()">
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Simple from "../layouts/Simple.vue"
import SearchBar from "./SearchBar.vue"
import SearchCriteria from "./SearchCriteria.vue"
import SelectedSlot from "./SelectedSlot.vue"
import LoadingSpinner from "./LoadingSpinner.vue"
import Logo from "./Logo.vue"
import Carousel from "primevue/carousel"
import { DateTime } from "luxon"
import VueHorizontalEs from "vue-horizontal"
import { XMarkIcon } from "@heroicons/vue/24/outline"
import _ from "lodash"

export default {
  name: "ChooseAlternative",
  components: {
    XMarkIcon,
    SearchBar,
    SearchCriteria,
    Simple,
    Carousel,
    LoadingSpinner,
    VueHorizontalEs,
    Logo,
    SelectedSlot,
  },
  props: {
    reservationId: {
      type: String,
      required: true,
    },
    showFullyBookedTimes: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["showOverlay", "hideOverlay", "skipSelectPackage"],
  data() {
    return {
      loading: false,
      displayModes: {
        time: "time",
        activity: "activity",
        venue: "venue",
      },
      showSearch: false,
      selectedSlot: {
        value: null,
        date: null,
        time: null,
      },
      selectedActivity: {
        id: null,
        name: null,
      },
      selectedVenue: {
        id: null,
        name: null,
      },
    }
  },
  computed: {
    searchResults() {
      return this.$store.state.searchResults
    },
    availabilities() {
      return this.$store.state.searchResults.availability
    },
    activeMode() {
      return this.$store.state.searchResults.alternative_view
    },
    searchParams() {
      return this.$store.state.searchParams
    },
    searchParamsForCurrentSearch() {
      return this.$store.state.searchParamsForCurrentSearch
    },
    searchForm() {
      return this.$store.state.searchFormData
    },
    headingText() {
      return this.$store.state.searchFormData?.heading
    },
    subHeadingText() {
      return this.$store.state.searchFormData?.sub_heading
    },
    selectedBookingSlot() {
      return this.$store.state.selectedBookingSlot
    },
    formData() {
      return {
        guests: this.searchParams.guests,
        when: this.selectedSlot.value,
        date: this.selectedSlot.date ?? null,
        time: this.selectedSlot.time ?? null,
        venue_id: this.selectedVenue.id,
        activity_id: this.selectedActivity.id,
        service_charge: "not_set",
        service_charge_percentage: this.selectedVenue.service_charge_percentage ?? 12.5,
      }
    },
    trackInteractProperties() {
      return {
        event: "alternative",
        properties: {
          location: window.location.href,
          venue: this.selectedVenue.name,
          date: this.selectedSlot.date,
          time: this.selectedSlot.time,
          activity: this.selectedActivity.name,
          mode: this.activeMode,
        },
        trackingServices: this.trackingGroups.all,
      }
    },
    canContinue() {
      return this.selectedActivity.id !== null && this.selectedSlot.value !== null
    },
    weekStart() {
      return DateTime.fromISO(Object.keys(this.availabilities)[0])
    },
  },
  methods: {
    showSearchBar() {
      this.$emit("showOverlay")
      this.$store.dispatch("updatePreviousSearchParams", this.$store.state.searchParams)
      this.showSearch = true
    },
    closeSearchBar() {
      this.showSearch = false
      if (this.$store.state.searchChanged) {
        this.$store.dispatch("updateSearchParams", this.$store.state.previousSearchParams)
      }
      this.updateSearchChanged(false)
      this.$emit("hideOverlay")
    },
    searchFinished() {
      this.showSearch = false
      this.$emit("hideOverlay")
    },
    sortByTime(times) {
      return Object.keys(times)
        .sort((time1, time2) => {
          const time1Obj = DateTime.fromISO(time1)
          const time2Obj = DateTime.fromISO(time2)
          return time1Obj - time2Obj
        })
        .reduce((result, key) => {
          result[key] = times[key]
          return result
        }, {})
    },
    getActivity(activityId) {
      const obj = _.pickBy(
        JSON.parse(JSON.stringify(this.searchResults.activities)),
        (a) => a.id === parseInt(activityId),
      )
      return obj[activityId]
    },
    getVenue(venueId) {
      const obj = _.pickBy(JSON.parse(JSON.stringify(this.searchResults.venues)), (a) => a.id === parseInt(venueId))
      return obj[venueId]
    },
    scrollToSelectedActivity() {
      if (this.isMobile) {
        this.$refs?.activities?.scrollToIndex(
          this.searchForm.activities.find((a) => a.id === this.selectedActivity.id) - 1,
        )
      }
    },
    selectSlot(date, time, fireTracking = true, updateSelectedBookingSlot = true) {
      const displayDate = DateTime.fromFormat(date + " " + time, "yyyy-MM-dd HH:mm").toFormat("EEE, dd MMM - HH:mm")
      this.selectedSlot.value = DateTime.fromFormat(date + " " + time, "yyyy-MM-dd HH:mm").toFormat("yyyy-MM-dd HH:mm")
      this.selectedSlot.date = date
      this.selectedSlot.time = time
      if (updateSelectedBookingSlot) {
        this.$store.dispatch("updateSelectedBookingSlot", {
          date: DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("EEE, dd MMM"),
          time: time,
          activity: this.selectedActivity,
          venue: this.selectedVenue.name,
          guests: this.searchParams.guests,
        })
      }

      if (fireTracking) {
        this.trackInteract(
          this.trackInteractProperties.event,
          this.trackInteractProperties.properties,
          this.trackInteractProperties.trackingServices,
        )
      }
    },
    selectActivity(activity, fireTracking = true, updateSelectedBookingSlot = true) {
      this.selectedActivity.id = activity.id
      this.selectedActivity.name = activity.name

      if (updateSelectedBookingSlot) {
        this.$store.dispatch("updateSelectedBookingSlot", {
          date: DateTime.fromFormat(this.selectedSlot.date, "yyyy-MM-dd").toFormat("EEE, dd MMM"),
          time: this.selectedSlot.time,
          activity: activity,
          venue: this.selectedVenue,
          guests: this.searchParams.guests,
        })
      }

      if (fireTracking) {
        this.trackInteract(
          this.trackInteractProperties.event,
          this.trackInteractProperties.properties,
          this.trackInteractProperties.trackingServices,
        )
      }
    },
    selectVenue(venue, fireTracking = true, updateSelectedBookingSlot = true) {
      this.selectedVenue.id = venue.id
      this.selectedVenue.name = venue.name

      if (updateSelectedBookingSlot) {
        this.$store.dispatch("updateSelectedBookingSlot", {
          date: DateTime.fromFormat(this.selectedSlot.date, "yyyy-MM-dd").toFormat("EEE, dd MMM"),
          time: this.selectedSlot.time,
          activity: this.selectedActivity,
          venue: venue,
          guests: this.searchParams.guests,
        })
      }

      if (fireTracking) {
        this.trackInteract(
          this.trackInteractProperties.event,
          this.trackInteractProperties.properties,
          this.trackInteractProperties.trackingServices,
        )
      }
    },
    changeActivity(activity) {
      this.selectedActivity = activity
    },
    updateSearchParams() {
      const params = this.searchParams
      if (this.activeMode === this.displayModes.activity) {
        params.activity = this.selectedActivity
      }
      if (this.activeMode === this.displayModes.time) {
        params.when.value = this.selectedSlot.value
        params.when.display = DateTime.fromFormat(this.selectedSlot.value, "yyyy-MM-dd HH:mm").toFormat(
          "EEE, dd MMM - HH:mm",
        )
      }
      if (this.activeMode === this.displayModes.venue) {
        params.venue = this.selectedVenue
      }
      this.$store.dispatch("updateSearchParams", params)
      this.$store.dispatch("updateSearchParamsForCurrentSearch", params)
    },
    updateSearchChanged(changed) {
      this.$store.dispatch("updateSearchChanged", changed)
    },
    completeSelection() {
      axios.post(route(this.getRoute("pending_change.update"), this.reservationId), this.formData).then((r) => {
        if (r.data.success) {
          //this.updateSearchParams()
          this.trackComplete(
            this.trackInteractProperties.event,
            this.trackInteractProperties.properties,
            this.trackingGroups.all,
          )
          this.$store.dispatch("updateSearchNextStep", "select_package")
          if (this.$store.state.searchNextPage) {
            this.$emit("skipSelectPackage")
          }
        }
      })
    },
    setDefaults() {
      const dateTime = DateTime.fromFormat(this.searchParams.when.value, "yyyy-MM-dd HH:mm")

      if (this.activeMode === this.displayModes.activity) {
        this.selectSlot(dateTime.toFormat("yyyy-MM-dd"), dateTime.toFormat("HH:mm"), false, false)
        this.selectVenue(this.searchParams.venue, false, false)
      }
      if (this.activeMode === this.displayModes.time) {
        this.selectActivity(
          this.searchForm.activities.find((a) => a.id === this.searchParams.activity.id),
          false,
          false,
        )
        this.selectVenue(this.searchParams.venue, false, false)
      }
      if (this.activeMode === this.displayModes.venue) {
        this.selectActivity(
          this.searchForm.activities.find((a) => a.id === this.searchParams.activity.id),
          false,
          false,
        )
        this.selectSlot(dateTime.toFormat("yyyy-MM-dd"), dateTime.toFormat("HH:mm"), false, false)
      }
    },
    getFromPriceForTime(time) {
      const dateTime = DateTime.fromFormat(this.searchParamsForCurrentSearch.when.value, "yyyy-MM-dd HH:mm")
      const date = dateTime.toFormat("yyyy-MM-dd")
      return Math.min(...Object.values(this.searchResults.availability[date][time].packages))
    },
  },
  mounted() {
    this.setDefaults()
    this.scrollToSelectedActivity()
    this.trackView(
      "alternative",
      {
        location: window.location.href,
        mode: this.activeMode,
      },
      this.trackingGroups.all,
    )
  },
}
</script>

<style scoped lang="scss">
.slidedown-enter-active,
.slidedown-leave-active {
  transition: max-height 0.3s ease-in;
}

.slidedown-enter-to,
.slidedown-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
