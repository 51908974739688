<template>
  <div>
    <transition name="slidedown">
      <div v-if="showSearch" class="absolute top-0 left-0 w-full bg-f-bg bo:bg-f-white py-8 z-70">
        <x-mark-icon
          class="w-8 absolute top-2 right-2 text-f-white bo:text-f-tertiary cursor-pointer"
          @click="closeSearchBar" />
        <div class="w-full flex items-center justify-center mb-8">
          <logo />
        </div>
        <div class="w-9/12 bg-f-tertiary bo:bg-f-white p-6 rounded-xl border-2 border-f-bg/[.07] hj:border-0 mx-auto">
          <SearchBar
            :activities="searchForm.activities"
            :venues="searchForm.venues"
            :reservation-id="reservationId"
            @search-finished="searchFinished" />
        </div>
      </div>
    </transition>

    <SavingMessage :loading="loading"></SavingMessage>

    <div class="hj:text-f-white">
      <div class="text-center">
        <SearchCriteria :activities="searchForm.activities" :venues="searchForm.venues" @click="showSearchBar" />
        <!--        <div v-if="selectedBookingSlot" class="flex flex-col items-center justify-center mt-10">-->
        <!--          <p class="text-f-bg bo:text-f-tertiary hj:text-f-white font-bold mb-2">Your selection</p>-->
        <!--          <SelectedSlot :activities="searchForm.activities" :venues="searchForm.venues" />-->
        <!--        </div>-->
      </div>

      <div class="w-full 2xl:w-9/12 mx-auto mt-16 2xl:mt-28">
        <div class="mb-10">
          <alert :key="alertKey" :flash="errorMessage" class="mx-auto mb-16 max-w-2xl" />
          <h1
            class="bo:font-graphik-bold hj:font-druk-heavy-italic text-f-bg hj:text-current text-3xl hj:text-4xl text-center mb-3">
            Select a {{ searchParams.activity.name }} package
          </h1>
          <p
            v-if="currentPackage && !currentPackageAvailable"
            class="text-f-bg hj:text-f-white bo:text-f-tertiary text-center">
            Your current package
            <span class="bo:font-graphik-medium hj:font-druk-bold"
              >{{ currentPackage.name }} (£{{ currentPackagePrice }} / person)</span
            >
            is no longer available. Please select another from the options below
          </p>
        </div>

        <div
          class="w-10/12 mx-auto flex md:flex-wrap flex-col md:flex-row md:items-stretch md:justify-center"
          :class="{ 'pointer-events-none': blockInteraction }">
          <div
            v-for="(packageItem, i) in packages"
            class="rounded-xl border border-f-bg hj:border-white p-6 md:mx-5 bg-f-tertiary/[.07] hj:bg-black b-drop-shadow-light hj:b-drop-shadow w-full md:w-[320px] xl:w-[260px] min-h-[420px] flex flex-col justify-between items-stretch mb-10"
            :class="{ 'border-4 pt-3': currentPackage?.id === packageItem.id }">
            <p
              v-if="currentPackage?.id === packageItem.id"
              class="text-f-bg bo:text-f-tertiary hj:text-f-white text-center mb-4">
              Current package
            </p>
            <div class="border-f-bg/[.07] hj:border-white/10 pb-3">
              <h2
                class="bo:font-graphik-semibold hj:font-druk-heavy-italic text-f-bg hj:text-current text-xl hj:text-4xl mb-2">
                {{ packageItem.name }}
              </h2>
              <p class="text-f-bg bo:text-f-tertiary/60 hj:text-f-white mb-4 text-sm">
                {{ getDuration(packageItem.id) }} minutes
              </p>
              <p
                v-if="$page.props.brand.code === 'hj'"
                class="text-f-bg bo:text-f-tertiary/60 hj:text-f-white mb-3 -mt-3 text-sm">
                Show time: {{ selectedBookingSlot.time }}
              </p>
              <p class="text-f-bg bo:text-f-tertiary hj:text-f-white text-2xl font-bold">
                &pound;{{ getPrice(packageItem.id) }}<span class="text-sm font-normal"> / person</span>
              </p>
            </div>

            <div class="flex flex-col justify-between items-stretch pt-3 grow">
              <div v-if="packageItem.hasOwnProperty('features') && packageItem.features.length > 0">
                <div v-for="feature in packageItem.features" class="mb-4">
                  <div v-if="feature" class="flex items-start justify-start">
                    <img src="/assets/selected-location.svg" alt="Star" class="hidden w-4 mr-3 bo:block" />
                    <div>
                      <div class="hidden w-2 h-2 mt-1 mr-4 rounded-xl bg-f-contrast hj:block"></div>
                    </div>
                    <p class="grow text-f-bg bo:text-f-tertiary/60 hj:text-f-white/90 leading-none">{{ feature }}</p>
                  </div>
                </div>
              </div>
              <p v-else class="text-f-bg bo:text-f-tertiary/60 hj:text-f-white/50">{{ packageItem.description }}</p>
              <button
                type="button"
                class="bo:font-graphik-medium hj:font-druk-bold bg-f-contrast font-bold hj:font-normal rounded-full hj:rounded-xl w-full py-3 text-f-tertiary hover:text-f-white hover:bg-f-tertiary hover:hj:bg-f-secondary hj:text-f-white hj:text-2xl hover:hj:bg-f-primary"
                @click="selectPackage(packageItem)">
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Simple from "../layouts/Simple.vue"
import SearchBar from "./SearchBar.vue"
import SearchCriteria from "./SearchCriteria.vue"
import { XMarkIcon } from "@heroicons/vue/24/outline"
import Logo from "./Logo.vue"
import LoadingSpinner from "../components/LoadingSpinner.vue"
import { DateTime } from "luxon"
import Alert from "../components/Alert.vue"
import SelectedSlot from "../components/SelectedSlot.vue"
import SavingMessage from "../components/SavingMessage.vue"

export default {
  name: "SelectPackage",
  components: {
    SavingMessage,
    SelectedSlot,
    LoadingSpinner,
    XMarkIcon,
    SearchCriteria,
    Simple,
    SearchBar,
    Logo,
    Alert,
  },
  props: {
    reservationId: {
      type: String,
      required: true,
    },
  },
  emits: ["showOverlay", "hideOverlay"],
  data() {
    return {
      loading: false,
      showSearch: false,
      errorMessage: null,
      alertKey: 1,
      blockInteraction: false,
    }
  },
  computed: {
    searchResults() {
      return this.$store.state.searchResults
    },
    packages() {
      const date = DateTime.fromFormat(this.selectedBookingSlot.date, "EEE, dd MMM")
      const packagesForThisTime =
        this.searchResults.availability?.[date.toFormat("yyyy-MM-dd")]?.[this.selectedBookingSlot.time]?.packages
      const packageIdsForThisTime = packagesForThisTime ? Object.keys(packagesForThisTime) : null
      return this.$store.state.searchResults.packages.filter((value, index, self) => {
        return (
          self.findIndex((v) => v.id === value.id) === index &&
          value.activity_id === this.searchParams.activity.id &&
          packageIdsForThisTime?.includes(value.id.toString())
        )
      })
    },
    searchParams() {
      return this.$store.state.searchParamsForCurrentSearch
    },
    searchForm() {
      return this.$store.state.searchFormData
    },
    currentPackage() {
      return this.$store.state.reservation && this.$store.state.reservation.package_id
        ? this.$store.state.reservation.package
        : null
    },
    selectedBookingSlot() {
      return this.$store.state.selectedBookingSlot
    },
    currentPackageAvailable() {
      return this.packages.find((p) => p.id === this.currentPackage.id) ?? false
    },
    currentPackagePrice() {
      let price = null
      Object.values(this.$store.state.cart.paid.lines).every((line) => {
        if (line.product.type === "package") {
          price = line.pricing.price
          return false
        }
      })
      return price
    },
  },
  methods: {
    showSearchBar() {
      this.$emit("showOverlay")
      this.blockInteraction = true
      this.$store.dispatch("updatePreviousSearchParams", this.$store.state.searchParams)
      this.showSearch = true
    },
    closeSearchBar() {
      this.showSearch = false
      if (this.$store.state.searchChanged) {
        this.$store.dispatch("updateSearchParams", this.$store.state.previousSearchParams)
      }
      this.updateSearchChanged(false)
      this.$emit("hideOverlay")
      this.blockInteraction = false
    },
    searchFinished() {
      this.showSearch = false
      this.$emit("hideOverlay")
    },
    getPrice(packageId) {
      const date = DateTime.fromFormat(this.selectedBookingSlot.date, "EEE, dd MMM")
      return this.searchResults.availability[date.toFormat("yyyy-MM-dd")][this.selectedBookingSlot.time].packages[
        packageId
      ]
    },
    getDuration(packageId) {
      const date = DateTime.fromFormat(this.selectedBookingSlot.date, "EEE, dd MMM")
      return this.searchResults.availability[date.toFormat("yyyy-MM-dd")][this.selectedBookingSlot.time].durations[
        packageId
        ]
    },
    updateSearchChanged(changed) {
      this.$store.dispatch("updateSearchChanged", changed)
    },
    updateSelectedBookingSlot() {
      if (!this.$store.state.selectedBookingSlot) {
        const when = DateTime.fromFormat(this.searchParams.when.value, "yyyy-MM-dd HH:mm")
        this.$store.dispatch("updateSelectedBookingSlot", {
          date: when.toFormat("EEE, dd MMM"),
          time: when.toFormat("HH:mm"),
          activity: this.searchParams.activity,
          venue: this.searchParams.venue,
          guests: this.searchParams.guests,
        })
      }
    },
    selectPackage(packageItem) {
      this.loading = true
      this.$emit("showOverlay")
      this.blockInteraction = true
      axios
        .post(route(this.getRoute("pending_change.update"), this.reservationId), {
          date: DateTime.fromFormat(this.selectedBookingSlot.date, "EEE, dd MMM").toFormat("yyyy-MM-dd"),
          time: this.selectedBookingSlot.time,
          guests: this.selectedBookingSlot.guests,
          venue_id: this.selectedBookingSlot.venue.id,
          activity_id: this.selectedBookingSlot.activity.id,
        })
        .then((r) => {
          if (r.data.success) {
            axios
              .post(route(this.getRoute("reservation.select_package"), this.reservationId), {
                package_id: packageItem.id,
              })
              .then((r) => {
                if (r.data.success) {
                  this.trackComplete(
                    "package",
                    {
                      location: window.location.href,
                      package_name: packageItem.name,
                    },
                    this.trackingGroups.all,
                  )

                  this.$inertia.get(route(this.getRoute("reservation.show_payment"), this.reservationId))
                }
              })
              .catch((err) => {
                this.loading = false
                this.$emit("hideOverlay")
                window.scrollTo(0, 0)
                this.errorMessage = {
                  type: "error",
                  message: err.response.data.errors?.[0],
                }
                this.alertKey++
              })
          }
        })
        .catch((err) => {
          this.loading = false
          this.$emit("hideOverlay")
          this.blockInteraction = false
          window.scrollTo(0, 0)
          this.errorMessage = {
            type: "error",
            message: err.response.data.errors?.[0],
          }
          this.alertKey++
        })
    },
  },
  mounted() {
    this.trackView(
      "package",
      {
        location: window.location.href,
      },
      this.trackingGroups.all,
    )
  },
  created() {
    this.updateSelectedBookingSlot()
  },
}
</script>

<style scoped></style>
